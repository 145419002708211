import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { BrowserRouter as Router } from "react-router-dom";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).lang : "en",
    fallbackLng: "en",
    react: { useSuspense: false },
  });

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );
}
