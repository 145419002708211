export const toggleSelectAll = <T>(
    responseData: T[],
    selectedRowIds: number[],
    setSelectedRowIds: React.Dispatch<React.SetStateAction<number[]>>,
    getId: (item: T) => number
) => {
    const responseIds = responseData.map((row) => getId(row));
    if (responseIds.every((id) => selectedRowIds.includes(id))) {
        setSelectedRowIds(selectedRowIds.filter((id) => !responseIds.includes(id)));
    } else {
        setSelectedRowIds([
            ...selectedRowIds,
            ...responseData.filter((row) => !selectedRowIds.includes(getId(row))).map((row) => getId(row))
        ]);
    }
};
