import { useTable } from "react-table";

interface TableProps<T extends object> {
    columns: Array<any>;
    data: T[];
}

const Table = <T extends object>({ columns, data }: TableProps<T>) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({
        columns,
        data,
    });

    return (
        <table {...getTableProps()} className="w-full text-black41 text-sm">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, columnIndex) => (
                            <th
                                {...column.getHeaderProps()}
                                className={`table__cell text-left ${columnIndex === 0 && "sticky-column"}`}
                            >
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="border-t border-gray-df">
                            {row.cells.map((cell, cellIndex) => (
                                <td
                                    {...cell.getCellProps()}
                                    className={`py-4 whitespace-nowrap table__cell ${cellIndex === 0 && "sticky-column"}`}
                                >
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
