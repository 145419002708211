
export const enum ValidationState {
  VALID,
  INVALID,
  WAITING,
};

export const enum ButtonTypes {
  PRIMARY,
  SECONDARY,
  CANCEL,
  ERROR,
  RESET,
};

export const enum AlertModalTypes { //????
  SOMETHING_WENT_WRONG,
};

export enum StatusBadgeTypes {
  SUBSCRIBED = 'Subscribed',
  UNSUBSCRIBED = 'Unsubscribed',
  RESPONDED = 'Responded',
  PENDING = 'pending',
}

export const enum SnackBarType {
  ADD,
  EDIT,
  DELETE,
  EXPORT,
  EXPORTFAILED,
  ADDEDITFAILED,
};
