import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

interface FilterTagProps {
    label: string;
    value: string;
    onClose: () => void;
}

const FilterTag: React.FC<FilterTagProps> = ({ label, value, onClose }) => {
    return (
        <div className="flex items-center py-[9px] px-3 h-10 rounded text-blue-primary bg-blue-e2 text-sm">
            <span className="font-medium">{label}:</span>
            <p className="mx-1 whitespace-pre-wrap font-bold">{value}</p>
            <FontAwesomeIcon
                onClick={onClose}
                className="text-base mx-1 cursor-pointer"
                icon={faTimes}
            />
        </div>
    );
};

export default FilterTag;
