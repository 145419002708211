import useAPI from "../../hooks/useAPI";

const SubscribersApi = () => {
  const { sendRequest } = useAPI();

  const getSubscribers = (url: string, subscriberPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: url,
      },
      subscriberPreccessResponse
    );
  };
  
  const exportSubscribers = (queryParams: string, exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `export-subscribers/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  const updateStatus = (subscribersPreccessResponse: (response: Response) => void, _id: number) => {
    sendRequest(
      {
        url: `subscribers/${_id}/`,
        method: "PATCH",
      },
      subscribersPreccessResponse
    );
  };

  return { getSubscribers, exportSubscribers, updateStatus };
};

export default SubscribersApi;
