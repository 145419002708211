import { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import TrainingCandidatesAPI from "../../../store/api/TrainingCandidatesApi";
import MainContainer from "../../UI/MainContainer";
import InformationSection from "../../UI/InformationSection";
import BreadCrumb from "../../UI/BreadCrumb";
import TrainingCandidateDetailsType from "../../../types/TrainingCandidateDetails";
import MainFooter from "../../UI/MainFooter";
import { format } from "date-fns";
import { getValueFromObjectList, getValueFromTupleList } from "../../../utils/getValue";
import MessageSection from "../../UI/MessageSection";

const TrainingCandidateDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const parentContainer = useRef<HTMLDivElement>(null);
  const informationContiner = useRef<HTMLElement>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [trainingCandidate, setTrainingCandidate] = useState<TrainingCandidateDetailsType>();
  const location = useLocation();
  const { referralList, organizationList } = location.state || {};
  const { getTrainingCandidateDetails, updateStatus, getCountryList } = TrainingCandidatesAPI();
  const [countryList, setCountryList] = useState<[string, string][]>([]);

  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });

  useEffect(() => {
    setShowLoading(true);
    onGetCountryList();
  }, []);

  useEffect(() => {
    if (countryList.length > 0)
      getTrainingCandidateDetails(id!, onGetTrainingCandidateDetails)
  }, [countryList]);

  const onGetCountryList = () => {
    getCountryList(
      async (response: Response) => {
        if (response.ok) {
          const result = await response.json();
          const countryTuples: [string, string][] = result.map(
            (country: { country__id: string; name: string }) => [country.country__id, country.name]
          );
          setCountryList(countryTuples);
        } else {
          console.error("Failed to fetch country list");
        }
      }
    );
  };

  const onGetTrainingCandidateDetails = async (response: Response) => {
    const result = await response.json();
    if (!response.ok) {
      setNotFoundMessage(result.clinic);
      return;
    }

    const processedTrainee = {
      ...result,
      clinic: getValueFromObjectList(result.clinic, organizationList),
      referral: getValueFromTupleList(result.referral, referralList),
      country: getValueFromTupleList(result.country_id, countryList)
    };

    setTrainingCandidate(processedTrainee);
    setShowLoading(false);
  };

  const updateStatusHandler = (_id: number, _status: string) => {
    updateStatus(async (response) => updateProcessResponse(response, _id, _status), _id, _status);
  };

  const updateProcessResponse = async (response: Response, _id: number, _status: string) => {
    if (response.ok) {
      setSnackbar({
        show: true,
        message: t("status_updated_successfully"),
      });
    }
  };

  const getFormattedDate = () => {
    return format(new Date(trainingCandidate!.contact_date), "dd/MM/yyyy kk:mm");
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      <main ref={parentContainer} className="alignment mb-8">
        {showLoading ? (
          <div className="flex w-full h-[500px] items-center justify-center">
            <CircularProgress size={64} />
          </div>
        ) : (trainingCandidate &&
          <>
            <header className="flex flex-col py-6 md:flex-row md:justify-between md:items-center md:mt-0">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-27">{trainingCandidate.first_name + " " + trainingCandidate.last_name}</h3>
                <BreadCrumb
                  links={[
                    { title: t("training_candidates"), path: "/training-candidates" },
                    { title: trainingCandidate.first_name + " " + trainingCandidate.last_name },
                  ]}
                />
              </div>
            </header>
            <div className="flex flex-col">
              <section ref={informationContiner}>
                <InformationSection
                  title={t("training_candidate_information")}
                  information={[
                    {
                      label: t("full_name"),
                      value: trainingCandidate.first_name + " " + trainingCandidate.last_name,
                    },
                    {
                      label: t("email"),
                      value: trainingCandidate.email,
                    },
                    {
                      label: t("phone"),
                      value: trainingCandidate.phone,
                    },
                    {
                      label: t("clinic"),
                      value: trainingCandidate.clinic,
                    },
                    {
                      label: t("supervisor"),
                      value: trainingCandidate.supervisor,
                    },
                    // {
                    //   label: t("address_postal_code"),
                    //   value: trainingCandidate.address + " - " + trainingCandidate.postal_code,
                    // },
                    {
                      label: t("country"),
                      value: trainingCandidate.country,
                    },
                    {
                      label: t("city"),
                      value: trainingCandidate.city,
                    },
                    {
                      label: t("hear_about_us"),
                      value: trainingCandidate.referral,
                    },
                  ]}
                />
                <MessageSection
                  className="mt-6"
                  title={t("message")}
                  message={trainingCandidate.message}
                  date={getFormattedDate()}
                  status={trainingCandidate.status}
                  onSave={(newStatus) => updateStatusHandler(parseInt(id!), newStatus)}
                />
              </section>
              <MainFooter />
            </div>
          </>
        )}
      </main>
    </MainContainer>
  );
};

export default TrainingCandidateDetails;
function setNotFoundMessage(clinic: any) {
  throw new Error("Function not implemented.");
}
