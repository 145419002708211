import useAPI from "../../hooks/useAPI";

const ContactsApi = () => {
  const { sendRequest } = useAPI();

  const getContacts = (url: string, contactProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: url,
      },
      contactProcessResponse
    );
  };

  const deleteContact = (contactProcessResponse: (response: Response) => void, _id: number) => {
    sendRequest(
      {
        url: `contacts/${_id}/`,
        method: "DELETE",
      },
      contactProcessResponse
    );
  };

  const exportContacts = (queryParams: string, exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `contacts/export/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  const getContactDetails = (id: string, contactProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `contacts/${id}/`,
      },
      contactProcessResponse
    );
  };

  const updateStatus = (
    contactProcessResponse: (response: Response) => void,
    _id: number,
    _status: string
  ) => {
    sendRequest(
      {
        url: `contacts/${_id}/`,
        method: "PATCH",
        body: JSON.stringify({
          status: _status,
        }),
      },
      contactProcessResponse
    );
  };

  return { getContacts, deleteContact, exportContacts, getContactDetails, updateStatus };
};

export default ContactsApi;
