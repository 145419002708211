import React from "react";
import { useTranslation } from "react-i18next";

import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";

const LastConfirmWarningModal: React.FC<{
  title: string;
  message: string;
  confirmButtonLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
  onBackdrop: () => void;
}> = ({ title, message, confirmButtonLabel, onCancel, onConfirm, onBackdrop }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="logout-modal" className="backdrop absolute" onClick={onBackdrop}>
      <section
        className="bg-white shadow-md p-6 cursor-auto w-[90%] 768:w-[492px] 480:w-[392px] rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-black41 text-lg font-semibold">
          {t(title)}
        </h3>
        <p className="text-sm text-black41 first-letter:uppercase mt-10">
          {t(message)}
        </p>
        <div className="flex gap-x-4 mt-10 justify-end">
          <Button type={ButtonTypes.CANCEL} onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button type={ButtonTypes.ERROR} onClick={onConfirm}>
            {t(confirmButtonLabel)}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default LastConfirmWarningModal;
