export const getValueFromTupleList = (key: string, list: [string, string][]) => {
  const found = list.find(item => item[0] === key);
  return found ? found[1] : key;
};

export const getValueFromObjectList = (key: string | number, list: { key: string | number; value: string }[]) => {
  const keyString = String(key);
  const found = list.find(item => String(item.key) === keyString);
  return found ? found.value : key;
};
