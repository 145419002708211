import React, { KeyboardEventHandler, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ValidationState } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

interface TextInputProps {
    value: string;
    placeholder: string;
    className?: string;
    dir?: string;
    inputClassName?: string;
    hasError: ValidationState | boolean;
    errorMessage: string;
    onTextInputChanged: (value: string) => void;
    onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}

const TextInput: React.FC<TextInputProps> = ({
    value,
    placeholder,
    className,
    dir,
    inputClassName,
    hasError,
    errorMessage,
    onTextInputChanged,
    onKeyPress
}) => {
    const { t } = useTranslation();
    const [textInputClicked, setTextInputClicked] = useState(false);

    const applyErrorStyle = typeof hasError === "boolean" ? hasError : hasError === ValidationState.INVALID;     

    return (
        <div className={className} dir={dir}>
            <div className="relative" dir={errorMessage === t("number_valid") ? "ltr" : undefined}>
                <input
                    className={`input ${!textInputClicked && "bg-[#F6F6F6]"
                        } ${inputClassName} ${applyErrorStyle && "placeholder:text-red-primary border-red-primary text-red-primary"}`}
                    onChange={(e) => onTextInputChanged(e.target.value)}
                    onClick={() => setTextInputClicked(true)}
                    onBlur={() => setTextInputClicked(false)}
                    value={value}
                    placeholder={placeholder}
                    autoComplete="off"
                    onKeyPress={onKeyPress}
                />
                {applyErrorStyle && (
                    <FontAwesomeIcon className="absolute top-[14.5px] right-4 text-red-primary" icon={faExclamationCircle} />
                )}
            </div>
            {applyErrorStyle && errorMessage && <p className="text-xs text-red-primary first-letter:uppercase">{errorMessage}</p>}
        </div>
    );
};

export default TextInput;
