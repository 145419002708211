import React from "react";
import "./App.css";
import { Routes, Navigate, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./components/auth/Login";
import NotFound404 from "./components/main/not-found-404/NotFound404";
import Contacts from "./components/main/contacts/Contacts";
import ContactDetails from "./components/main/contacts/ContactDetails";
import TrainingCandidates from "./components/main/training-candidates/TrainingCandidates";
import TrainingCandidateDetails from "./components/main/training-candidates/TrainingCandidateDetails";
import Subscribers from "./components/main/subscribers/Subscribers";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound404 />} />

      <Route path="/contacts" element={<PrivateRoute element={<Contacts />} />} />
      <Route path="/contact/:id" element={<PrivateRoute element={<ContactDetails />} />} />
      <Route path="/training-candidates" element={<PrivateRoute element={<TrainingCandidates />} />} />
      <Route path="/training-candidate/:id" element={<PrivateRoute element={<TrainingCandidateDetails />} />} />
      <Route path="/subscribers" element={<PrivateRoute element={<Subscribers />} />} />

      <Route path="/" element={<Navigate to="/contacts" replace />} />
    </Routes>
  );
};

export default App;
