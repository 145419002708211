import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainContainer from "../../UI/MainContainer";
import MainFooter from "../../UI/MainFooter";
import Button from "../../UI/Button";
import { ButtonTypes, SnackBarType, StatusBadgeTypes } from "../../../utils/Constants";
import SearchSection from "../../UI/SearchSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import NoResultMessage from "../../UI/NoResultMessage";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import Pagination from "../../UI/Pagination";
import Table from "../../UI/Table";
import StatusBadge from "../../UI/StatusBadge";
import { Column, Row } from "react-table";
import IconTooltip from "../../UI/IconTooltip";
import Contact from "../../../types/Contact";
import LoadingSpinner from "../../UI/LoadingSpinner";
import Moment from "react-moment";
import ContactsApi from "../../../store/api/ContactsApi";
import saveAs from "file-saver";
import { getDate } from "../../../utils/getDate";
import FilterSection, { FilterState } from "./ContactsFilterSection";
import { useNavigate } from "react-router-dom";
import { renderSortableHeader, SortConfig } from "../../../utils/renderSortableHeader";
import { toggleSelectAll } from "../../../utils/toggleSelectAll";
import { toggleRowSelection } from "../../../utils/toggleRowSelection";

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [responseData, setResponseData] = useState<Contact[]>([]);
  const [filterState, setFilterState] = useState<FilterState>({
    status: "AL",
    dateFrom: null,
    dateTo: null,
  });
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [iconTooltipData, setIconTooltipData] = useState<{ id: number; type: string; top: number; left: number } | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<number | null>(null);
  const { getContacts, deleteContact, exportContacts } = ContactsApi();

  const getContactsList = () => {
    setShowLoading(true);
    getContacts(`${"contacts/"}${constructQueryParams()}`, async (response: Response) => {
      const result = await response.json();
      if (!response.ok) {
        return;
      }

      setResponseData(result.contacts);
      setDataCount(result.total_count);
      setShowLoading(false);
    });
  };

  const constructQueryParams = (includePagination: boolean = true) => {
    let queryParams: string[] = [];
    if (includePagination) queryParams.push(`page_number=${pageIndex + 1}&page_size=${pageSize}`);
    if (searchInput) {
      queryParams.push(`search_contact=${searchInput}`);
    }
    if (filterState.status !== "AL") {
      queryParams.push(`contact_status=${filterState.status}`);
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`contact_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`contact_to=${getDate(filterState.dateTo.toString(), false)}`);
    }
    if (sortConfig) {
      queryParams.push(`sort_by=${sortConfig.column}&sort_type=${sortConfig.sortType}`);
    }
    return `?${queryParams.join("&")}`;
  };

  useEffect(() => {
    setPageIndex(0);
  }, [searchInput, filterState]);

  useEffect(() => {
    getContactsList();
  }, [pageIndex, pageSize, searchInput, filterState, sortConfig]);

  const columns: Column<Contact>[] = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              onChange={() => toggleSelectAll(responseData, selectedRowIds, setSelectedRowIds, (contact) => contact.id)}
              checked={responseData.length > 0 && responseData.every((row) => selectedRowIds.includes(row.id))}
              className="table__checkbox mr-4"
            />
            {renderSortableHeader(props.column, t("first_name"), sortConfig, setSortConfig)}
          </div>
        ),
        accessor: "first_name",
        Cell: ({ row }: { row: Row<Contact> }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedRowIds.includes(row.original.id)}
              onChange={() => toggleRowSelection(row.original.id, setSelectedRowIds)}
              className="table__checkbox mr-4"
            />
            <span>{row.original.first_name}</span>
          </div>
        ),
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("last_name"), sortConfig, setSortConfig),
        accessor: "last_name",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("phone"), sortConfig, setSortConfig),
        accessor: "phone",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("email"), sortConfig, setSortConfig),
        accessor: "email",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("message"), sortConfig, setSortConfig),
        accessor: "message",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("contact_date"), sortConfig, setSortConfig),
        accessor: "contact_date",
        Cell: ({ value }) => <Moment format="DD/MM/YYYY">{value}</Moment>,
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("status"), sortConfig, setSortConfig),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          let statusType: StatusBadgeTypes | undefined;
          switch (value) {
            case "RS":
              statusType = StatusBadgeTypes.RESPONDED;
              break;
            case "PD":
              statusType = StatusBadgeTypes.PENDING;
              break;
            default:
              statusType = undefined;
          }
          return statusType ? <StatusBadge type={statusType} /> : <span>{t("unknown_status")}</span>;
        },
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div className="flex gap-4 text-blue-primary text-base">
            <div
              className="relative group px-2 py-[3px] my-auto hover:bg-gray-f2 cursor-pointer rounded"
              onClick={() => navigate(`/contact/${row.original.id}`)}
              onMouseEnter={(event) => handleMouseEnter(event, row.original.id, "preview")}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faEye} />
            </div>

            <div
              className="relative group px-2 py-[3px] my-auto hover:bg-gray-f2 cursor-pointer rounded"
              onClick={() => {
                setContactToDelete(row.original.id);
                setShowDeleteModal(true);
              }}
              onMouseEnter={(event) => handleMouseEnter(event, row.original.id, "delete")}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        ),
      },
    ],
    [t, selectedRowIds, responseData, sortConfig]
  );

  const handleMouseEnter = useCallback((event: React.MouseEvent, id: number, type: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setIconTooltipData({
      id,
      type,
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX + 10,
    });
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIconTooltipData(null);
  }, []);

  const renderIconTooltip = () => {
    if (!iconTooltipData) return null;

    const labels: { [key: string]: string } = {
      preview: "Preview",
      delete: "Delete",
    };

    const label = labels[iconTooltipData.type];
    return <IconTooltip tooltipData={iconTooltipData} label={label || ""} />;
  };

  const deleteHandler = (_id: number) => {
    deleteContact((response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("record_deleted_successfully"),
          type: SnackBarType.EXPORT,
          show: true,
        });
        const newData = responseData.filter((candidate) => candidate.id !== _id);
        setResponseData(newData);
      }
    }, _id);
    setShowDeleteModal(false);
    setContactToDelete(null);
  };

  const exportHandler = () => {
    setShowLoadingSpinner(true);
    exportContacts(
      constructQueryParams(false) +
      (selectedRowIds.length > 0
        ? `&selected_contacts=${selectedRowIds.map((selectedItem) => selectedItem).join(",")}`
        : ""),
      async (response: Response) => {
        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, `"contacts".csv`);
          setSnackbar({
            message: t("export_completely_message"),
            type: SnackBarType.EXPORT,
            show: true,
          });
          setShowLoadingSpinner(false);
        } else {
          setShowLoadingSpinner(false);
        }
      }
    );
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>

      {showLoadingSpinner && (
        <LoadingSpinner titleKey={t("export_to_csv")} onBackdrop={() => setShowLoadingSpinner(false)} />
      )}

      <main className="alignment mt-4">
        <header className="flex flex-row justify-between items-center flex-wrap gap-x-32 gap-y-2">
          <h3 className="text-lg font-semibold text-gray-27 my-auto">{t("contacts")}</h3>
          {responseData.length > 0 && !showLoading && (
            <Button onClick={exportHandler} type={ButtonTypes.PRIMARY} className="max-w-[155px]">
              {t("export_to_csv")}
            </Button>
          )}
        </header>

        <div className="bg-white rounded shadow px-6 mt-4">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
            }}
            onSliderClicked={() => {
              setShowFilterSection((preState) => !preState);
            }}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && (
            <FilterSection
              onApply={(appliedFilterState: FilterState) => {
                setFilterState(appliedFilterState);
              }}
              onReset={() => {
                setFilterState({
                  status: "AL",
                  dateFrom: null,
                  dateTo: null,
                });
              }}
              initialFilterState={filterState}
            />
          )}
        </div>

        <div className="relative bg-white rounded shadow px-4 mt-[6px] pb-6">
          <div className="mb-10 overflow-x-auto z-10 min-h-[400px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} />
              </div>
            ) : responseData.length > 0 ? (
              <Table<Contact> columns={columns} data={responseData} />
            ) : (
              <NoResultMessage noResultOrNoData={true} />
            )}
          </div>

          {!showLoading && responseData.length > 0 && (
            <Pagination
              dropdownKeys={["5", "10", "20"]}
              pageIndex={pageIndex}
              pageSize={pageSize}
              dataCount={dataCount}
              onPageChange={setPageIndex}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                setPageIndex(0);
              }}
            />
          )}
        </div>

        {renderIconTooltip()}

        {showDeleteModal && (
          <LastConfirmWarningModal
            title="delete"
            message="delete_record_message"
            confirmButtonLabel="yes_delete"
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => {
              if (contactToDelete !== null) deleteHandler(contactToDelete);
            }}
            onBackdrop={() => setShowDeleteModal(false)}
          />
        )}

        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default Contacts;
