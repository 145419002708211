import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSlidersH } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

interface SearchSectionProps {
  onSearch: (searchInput: string) => void;
  isFilterMode?: boolean;
  onSliderClicked?: () => void;
}

const SearchSection: React.FC<SearchSectionProps> = ({
  onSearch,
  isFilterMode = false,
  onSliderClicked,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (isFilterMode) {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isFilterMode) {
      onSearch(searchTerm);
    }
  };

  const handleSliderClick = () => {
    if (onSliderClicked) onSliderClicked();
  };

  return (
    <section className="mx-auto flex justify-between items-center py-4">
      <div className="relative w-[260px]">
        <FontAwesomeIcon
          className="absolute text-sm text-black41 left-4 top-4"
          icon={faSearch}
        />
        <input
          className="w-full py-[9px] px-3 pl-[40px] text-sm text-black41 rounded border border-gray-df bg-gray-f6 placeholder:text-gray-d7 hover:bg-white focus:bg-white focus:border-blue-71 focus:caret-blue-71 outline-none"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={t("search")}
        />
      </div>
      <div
        className="w-[28px] h-[28px] ml-2 rounded grid place-items-center cursor-pointer hover:bg-gray-f5"
        onClick={handleSliderClick}
      >
        <FontAwesomeIcon icon={faSlidersH} className="text-blue-primary" />
      </div>
    </section>
  );
};

export default SearchSection;
