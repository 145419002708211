import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_AUTH_DATA } from "../components/auth/Login";

const useAPI = () => {
  const authData = localStorage.getItem("auth_data")
    ? JSON.parse(localStorage.getItem("auth_data")!)
    : DEFAULT_AUTH_DATA;
  const navigate = useNavigate();

  const sendRequest = useCallback(
    async (
      requestData: {
        url: string;
        method?: string;
        contentType?: boolean;
        body?: string | FormData;
        noAuthorization?: boolean;
      },
      processResponse: (response: Response) => void
    ) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}${requestData.url}`, {
          method: requestData.method ?? "GET",
          headers: requestData.contentType
            ? {
                Authorization: `Bearer ${authData.access}`,
                "Accept-Language": JSON.parse(localStorage.getItem("auth_data") ?? "{}")?.lang || "en",
              }
            : {
                "Content-Type": "application/json",
                Authorization: requestData.noAuthorization ? "" : `Bearer ${authData.access}`,
                "Accept-Language": JSON.parse(localStorage.getItem("auth_data") ?? "{}")?.lang || "en",
              },
          body: requestData.body ?? null,
        });
        if (response.status === 403) {
          localStorage.removeItem("auth_data");
          navigate("/login", { replace: true });
          return;
        }
        processResponse(response);
      } catch (error) {
        console.error("Error in API request:", error);
      }
    },
    []
  );

  return {
    sendRequest,
  };
};

export default useAPI;
