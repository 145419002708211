import useAPI from "../../hooks/useAPI";

const AuthAPI = () => {
  const { sendRequest } = useAPI();

  const loginRequest = (
    email: string,
    password: string,
    token: string,
    onLoginResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: "token/",
        method: "POST",
        body: JSON.stringify({ email, password, token }),
        noAuthorization: true,
      },
      onLoginResponse
    );
  };

  return { loginRequest };
};

export default AuthAPI;
