import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import vaccineAnimation from "./vaccine.json";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import background from "../../../images/background.png";

const NotFound404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main className="page p-1">
      <img
        className="w-auto h-full absolute right-0 bottom-0 -z-10"
        src={background}
        alt=""
      />
      <section className="flex items-center flex-col-reverse 960:flex-row">
        <Lottie
          style={{ height: 400 }}
          animationData={vaccineAnimation}
          autoPlay={true}
          loop={true}
        />
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-9xl text-blue-primary">404</h1>
          <h2 className="font-bold text-4xl text-blue-primary capitalize text-center">
            {t("page_not_found")}
          </h2>
          <Button
            className="mt-8"
            type={ButtonTypes.PRIMARY}
            onClick={() =>  navigate("/contacts", { replace: true })}
            
          >
            {t("contacts")}
          </Button>
        </div>
      </section>
    </main>
  );
};

export default NotFound404;
