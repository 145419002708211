import React from "react";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  inputClassName?: string;
  containerClassName?: string;
  selected: Date | null;
  placeholderText: string;
  onChange: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const DatePicker: React.FC<DatePickerProps> = ({
  inputClassName = "",
  containerClassName = "",
  selected,
  placeholderText,
  onChange,
  minDate,
  maxDate,
}) => {
  return (
    <div className={`relative z-20 ${containerClassName}`}>
      <FontAwesomeIcon
        icon={faCalendarAlt}
        className="text-sm text-gray-d7 absolute right-3 top-3.5 z-10"
      />
      <ReactDatePicker
        className={`py-[9px] px-3 text-sm text-black41 rounded border border-gray-df bg-gray-f6 placeholder:text-gray-d7 hover:bg-white focus:bg-white focus:border-blue-71 focus:caret-blue-71 outline-none ${inputClassName}`}
        selected={selected}
        placeholderText={placeholderText}
        onChange={onChange}
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default DatePicker;
