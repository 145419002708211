import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { AlertModalTypes, ButtonTypes } from "../../utils/Constants";
import Button from "./Button";

const AlertModal: React.FC<{
  type: AlertModalTypes;
  onAction: () => void;
  onBackdrop: () => void;
}> = ({ type, onAction, onBackdrop }) => {
  const { t } = useTranslation();
  let title, message, action, icon, buttonType, color;

  if (type === AlertModalTypes.SOMETHING_WENT_WRONG) {
    title = t("something_went_wrong");
    message = t("something_went_wrong_message");
    action = t("refresh");
    icon = faExclamationCircle;
    buttonType = ButtonTypes.ERROR;
    color = "red-primary";
  }

  return (
    <div className="backdrop" onClick={() => onBackdrop()}>
      <section
        className="cursor-auto shadow-md bg-white w-full 480:w-auto 480:rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative text-center">
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-5 right-5 text-gray-41 text-sm cursor-pointer"
            onClick={() => onBackdrop()}
          />
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className={`text-8xl mt-5 text-${color}`}
            />
          )}
          <h3
            className={`text-xl font-bold text-${color} first-letter:uppercase`}
          >
            {title}
          </h3>
        </div>
        <div className="bg-gray-d725 mt-7 p-8 text-center">
          <p className="text-sm text-gray-41">{message}</p>
          {buttonType && (
            <Button
              className="mt-5"
              type={buttonType}
              onClick={() => onAction()}
            >
              {action}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default AlertModal;
