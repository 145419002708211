import { useState, useEffect } from "react";
import { ValidationState } from "../utils/Constants";

export const EMAIL_REGEX =
  /^([a-z0-9._-]+)@([a-z0-9._-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

const useEmailValidation = () => {
  const [email, setEmail] = useState<string>();
  const [emailHasError, setEmailHasError] = useState(ValidationState.WAITING);

  useEffect(() => {
    if (email !== undefined) {
      const timeout = setTimeout(() => {
        if (EMAIL_REGEX.test(email.trim().toLocaleLowerCase())) {
          setEmailHasError(ValidationState.VALID);
        } else {
          setEmailHasError(ValidationState.INVALID);
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [email]);

  return { email, setEmail, emailHasError, setEmailHasError };
};

export default useEmailValidation;
