import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

export enum DropdownType {
  PRIMARY,
  SECONDARY,
}

interface DropdownProps {
  type: DropdownType;
  keys: string[];
  selectedKey: string;
  onOptionSelected: (key: string) => void;
  getValueByKey?: (key: string) => string;
  extraAvailableOptionsContainerClassName?: string;
}

const styles = {
  primary: {
    root: "w-[260px]",
    selectedOption:
      "flex justify-between items-center py-[9px] px-4 border border-gray-df rounded cursor-pointer hover:bg-white bg-gray-f6 focus:bg-white focus:border-blue-71 focus:caret-blue-71",
    clickedState: "border-blue-71 bg-white",
    text: "text-sm capitalize text-black41",
    option:
      "px-4 py-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6",
    optionsContainer: "top-12",
  },
  secondary: {
    root: "w-[72px]",
    selectedOption:
      "flex justify-between items-center p-2 border border-gray-df rounded cursor-pointer bg-white",
    clickedState: "border-blue-71 bg-white",
    text: "text-black41 text-sm",
    option:
      "p-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6",
    optionsContainer: "top-10",
  },
};

const Dropdown: React.FC<DropdownProps> = ({
  type,
  keys,
  selectedKey,
  onOptionSelected,
  getValueByKey,
  extraAvailableOptionsContainerClassName,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const currentStyles = styles[type === DropdownType.PRIMARY ? "primary" : "secondary"];

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative ${currentStyles.root}`}
      ref={dropdownRef}
    >
      <div
        className={`${currentStyles.selectedOption} ${showMenu ? currentStyles.clickedState : ""}`}
        onClick={() => setShowMenu(!showMenu)}
        aria-expanded={showMenu}
        aria-haspopup="listbox"
        role="button"
      >
        <p className={currentStyles.text}>
          {getValueByKey ? getValueByKey(selectedKey) : t(selectedKey.toLowerCase())}
        </p>
        <FontAwesomeIcon icon={faAngleDown} className="text-gray-b5" />
      </div>

      {showMenu && (
        <ul
          className={`absolute left-0 right-0 bg-white rounded shadow py-1 z-40 ${currentStyles.optionsContainer} ${extraAvailableOptionsContainerClassName || ""}`}
          role="listbox"
        >
          {keys.map((key) => (
            <li
              key={key}
              className={currentStyles.option}
              onClick={() => {
                onOptionSelected(key);
                setShowMenu(false);
              }}
              role="option"
              aria-selected={key === selectedKey}
            >
              {getValueByKey ? getValueByKey(key) : t(key.toLowerCase())}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
