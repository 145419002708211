import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownType } from "./Dropdown";
import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";

interface MessageSectionProps {
  className?: string;
  title: string;
  message: string;
  date: string;
  status: string;
  onSave: (newStatus: string) => void;
}

const MessageSection: React.FC<MessageSectionProps> = ({
  className = "",
  title,
  message,
  date,
  status,
  onSave,
}) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);
  const [trainingCandidateStatus, setTrainingCandidateStatus] =
    useState(status);

  const toggleShowInfo = () => setShowInfo((prev) => !prev);

  const handleSaveClick = () => {
    onSave(trainingCandidateStatus);
  };

  return (
    <section className={`bg-white rounded ${className}`}>
      <div
        className={`px-6 py-3.5 flex justify-between items-center rounded-t bg-gray-612d ${
          !showInfo ? "rounded-b" : ""
        }`}
      >
        <p
          className={
            showInfo
              ? "text-sm text-black41"
              : "text-[15px] text-blue-secondary font-semibold"
          }
        >
          {title}
        </p>
        <FontAwesomeIcon
          icon={faAngleUp}
          className={`text-sm text-gray-8d cursor-pointer duration-300 transform ${
            showInfo ? "rotate-0" : "rotate-180"
          }`}
          onClick={toggleShowInfo}
        />
      </div>
      {showInfo && (
        <div className="transition-max-height duration-300">
          <div className="text-sm text-black41 p-6">
            <div>
              <p className="font-semibold">{message}</p>
              <p className="text-right mt-4">{date}</p>
            </div>
            <div className="mt-6 flex flex-col">
              <label className="mb-2">{t("status")}</label>
              <Dropdown
                type={DropdownType.PRIMARY}
                onOptionSelected={setTrainingCandidateStatus}
                keys={["AL", "PD", "RS"]}
                selectedKey={trainingCandidateStatus}
              />
            </div>
            <div className="mt-14 relative text-right">
              <Button onClick={handleSaveClick} type={ButtonTypes.PRIMARY}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MessageSection;
