import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import ContactsAPI from "../../../store/api/ContactsApi";
import MainContainer from "../../UI/MainContainer";
import InformationSection from "../../UI/InformationSection";
import BreadCrumb from "../../UI/BreadCrumb";
import Contact from "../../../types/Contact";
import MainFooter from "../../UI/MainFooter";
import { format } from "date-fns";
import MessageSection from "../../UI/MessageSection";

const ContactDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const parentContainer = useRef<HTMLDivElement>(null);
  const informationContiner = useRef<HTMLElement>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [contact, setContact] = useState<Contact>();
  const { getContactDetails, updateStatus } = ContactsAPI();

  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });

  useEffect(() => {
    getContactDetails(id!, onGetContactDetails)
  }, []);

  const onGetContactDetails = async (response: Response) => {
    const result = await response.json();
    if (!response.ok) {
      setNotFoundMessage(result.clinic);
      return;
    }
    
    setContact(result);
    setShowLoading(false);
  };

  const updateStatusHandler = (_id: number, _status: string) => {
    updateStatus(async (response) => updateProcessResponse(response, _id, _status), _id, _status);
  };

  const updateProcessResponse = async (response: Response, _id: number, _status: string) => {
    if (response.ok) {
      setSnackbar({
        show: true,
        message: t("status_updated_successfully"),
      });
    }
  };

  const getFormattedDate = () => {
    return format(new Date(contact!.contact_date), "dd/MM/yyyy kk:mm");
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      <main ref={parentContainer} className="alignment mb-8">
        {showLoading ? (
          <div className="flex w-full h-[500px] items-center justify-center">
            <CircularProgress size={64} />
          </div>
        ) : (contact &&
          <>
            <header className="flex flex-col py-6 md:flex-row md:justify-between md:items-center md:mt-0">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-27">{contact.first_name + " " + contact.last_name}</h3>
                <BreadCrumb
                  links={[
                    { title: t("contacts"), path: "/contacts" },
                    { title: contact.first_name + " " + contact.last_name },
                  ]}
                />
              </div>
            </header>
            <div className="flex flex-col">
              <section ref={informationContiner}>
                <InformationSection
                  title={t("contact_information")}
                  information={[
                    {
                      label: t("first_name"),
                      value: contact.first_name,
                    },
                    {
                      label: t("last_name"),
                      value: contact.last_name,
                    },
                    {
                      label: t("email"),
                      value: contact.email,
                    },
                    {
                      label: t("phone"),
                      value: contact.phone,
                    }
                  ]}
                />
                <MessageSection
                  className="mt-6"
                  title={t("message")}
                  message={contact.message}
                  date={getFormattedDate()}
                  status={contact.status}
                  onSave={(newStatus) => updateStatusHandler(parseInt(id!), newStatus)}
                />
              </section>
              <MainFooter />
            </div>
          </>
        )}
      </main>
    </MainContainer>
  );
};

export default ContactDetails;
function setNotFoundMessage(clinic: any) {
  throw new Error("Function not implemented.");
}
