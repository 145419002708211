import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/pro-light-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const INPUT_PASSWORD_TYPE = "password";
const INPUT_TEXT_TYPE = "text";

const PasswordInput: React.FC<{
  hasError: boolean;
  onPasswordChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}> = ({ hasError, onPasswordChange, placeholder, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputContainer = useRef<HTMLInputElement>(null);

  return (
    <div className="mt-4 relative" dir="ltr">
      {showPassword ? (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-4 cursor-pointer text-gray-27"
          icon={faEye}
          onClick={() => {
            setShowPassword(false);
            passwordInputContainer.current!.type = INPUT_PASSWORD_TYPE;
          }}
        />
      ) : (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-4 cursor-pointer text-gray-27"
          icon={faEyeSlash}
          onClick={() => {
            setShowPassword(true);
            passwordInputContainer.current!.type = INPUT_TEXT_TYPE;
          }}
        />
      )}
      {hasError && (
        <FontAwesomeIcon
          className="absolute top-[14.5px] right-[42px] text-red-primary"
          icon={faExclamationCircle}
        />
      )}
      <input
        ref={passwordInputContainer}
        className={`input w-full pr-16 placeholder:capitalize ${
          hasError && "placeholder:text-red-primary border-red-primary text-red-primary"
        }`}
        type="password"
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          onPasswordChange(e.target.value);
        }}
      />
    </div>
  );
};

export default PasswordInput;
