import { ColumnInstance } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';

export type SortConfig = {
    column: string;
    sortType: 'Ascending' | 'Descending';
};

export const renderSortableHeader = <T extends object,>(
    column: ColumnInstance<T>,
    label: string,
    sortConfig: SortConfig | undefined,
    setSortConfig: (config: SortConfig | undefined) => void
) => {
    const isSorted = sortConfig?.column === column.id;
    const isSortedDesc = isSorted && sortConfig?.sortType === 'Descending';
    const isSortedAsc = isSorted && sortConfig?.sortType === 'Ascending';

    const handleSort = () => {
        if (isSorted) {
            setSortConfig(isSortedDesc ? undefined : { column: column.id, sortType: 'Descending' });
        } else {
            setSortConfig({ column: column.id, sortType: 'Ascending' });
        }
    };

    return (
        <div className="flex items-center cursor-pointer" onClick={handleSort}>
            {label}
            <div className="flex flex-col ml-2">
                <FontAwesomeIcon icon={faAngleUp} className={isSortedAsc ? 'text-black41' : 'text-gray-df'} />
                <FontAwesomeIcon icon={faAngleDown} className={isSortedDesc ? 'text-black41' : 'text-gray-df'} />
            </div>
        </div>
    );
};
